<template>
  <a-drawer width="60%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
<!--      <div class="table-page-search-wrapper">-->
<!--        <a-form layout="inline">-->
<!--          <a-row :gutter="48">-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="内容" prop="content">-->
<!--                <a-input v-model="queryParam.content" placeholder="请输入内容" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="!advanced && 8 || 24" :sm="24">-->
<!--              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">-->
<!--                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>-->
<!--                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>-->
<!--              </span>-->
<!--            </a-col>-->
<!--          </a-row>-->
<!--        </a-form>-->
<!--      </div>-->

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <!-- <a-divider type="vertical"/> -->
          <a @click="handleDelete(record)">
            <a-icon type="delete" />刪除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-drawer>
</template>

<script>
import {pageMobileChangeRecord}  from '@/api/platform/mobileChangeRecord'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'ShowMobileRecord',
  components: {

  },
  mixins: [tableMixin],
  data () {
    return {
      formTitle: '手機號變更記錄列表',
      open: false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        byUserId: null,
        resourceType: null,
        resourceId: null,
        commentId: null,
        replyId: null,
        userId: null,
        content: null,
        replyCommentUserId: null,
        replyNum: null,
        likeNum: null,
        type: null,
        isDisable: null,
        atUserIds: null,
        atUserNames: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '作品類型',
        //   dataIndex: 'resourceType',
        //   ellipsis: true,
        //   align: 'center',
        //   customRender: function (value, record) {
        //     if (value == 1) {
        //       return "視頻"
        //     }
        //     if (value == 2) {
        //       return "赛事"
        //     }
        //     if (value == 3) {
        //       return "PK"
        //     }
        //   }
        // },
        {
          title: '變更前',
          dataIndex: 'beforeValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '變更後',
          dataIndex: 'afterValue',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '變更時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  created () {
    // this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    /** 查詢作品-评价回复列表 */
    getList (id) {
      console.log(id)
      this.formType = 1
      this.open = true
      this.loading = true
      this.queryParam.userId = id
      pageMobileChangeRecord(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList(this.queryParam.userId)
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        byUserId: undefined,
        resourceType: undefined,
        userId: this.queryParam.userId,
        commentId: undefined,
        replyId: undefined,
        content: undefined,
        replyCommentUserId: undefined,
        replyNum: undefined,
        likeNum: undefined,
        type: undefined,
        isDisable: undefined,
        atUserIds: undefined,
        atUserNames: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList(this.queryParam.userId)
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList(this.queryParam.userId)
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
  }
}
</script>
