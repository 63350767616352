import request from '@/utils/request'


// 查询用户手机号变更记录列表
export function listMobileChangeRecord(query) {
  return request({
    url: '/platform/user-mobile-change-record/list',
    method: 'get',
    params: query
  })
}

// 查询用户手机号变更记录分页
export function pageMobileChangeRecord(query) {
  return request({
    url: '/platform/user-mobile-change-record/page',
    method: 'get',
    params: query
  })
}

// 查询用户手机号变更记录详细
export function getMobileChangeRecord(data) {
  return request({
    url: '/platform/user-mobile-change-record/detail',
    method: 'get',
    params: data
  })
}

// 新增用户手机号变更记录
export function addMobileChangeRecord(data) {
  return request({
    url: '/platform/user-mobile-change-record/add',
    method: 'post',
    data: data
  })
}

// 修改用户手机号变更记录
export function updateMobileChangeRecord(data) {
  return request({
    url: '/platform/user-mobile-change-record/edit',
    method: 'post',
    data: data
  })
}

// 删除用户手机号变更记录
export function delMobileChangeRecord(data) {
  return request({
    url: '/platform/user-mobile-change-record/delete',
    method: 'post',
    data: data
  })
}
